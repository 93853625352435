<template>
  <div class="notifications-page">
    <Pane
      v-for="notification in $store.getters.notifications"
      :key="notification.id"
      class="notification-pane"
    >
      <div class="row notification-pane__info">
        <div class="col">
          {{ moment(notification.created).format("DD MMMM YYYY") }}
        </div>
        <div
          class="col notification-pane__kind"
          :class="{
            'notification-pane__kind--danger': notification.level === 'danger',
            'notification-pane__kind--success':
              notification.type === 'personal',
          }"
        >
          {{ notificationKinds[notification.type] }}
        </div>
      </div>
      <h3 class="mb-3 mt-0">{{ notification.title }}</h3>
      <div
        class="notification-pane__content"
        v-html="formatter_text(notification.text)"
      />
    </Pane>
    <Pane v-if="!$store.getters.notifications.length">
      <div class="notifications-page--empty">
        {{ $t("notification.emptyNotify") }}
      </div>
    </Pane>
  </div>
</template>

<script>
import moment from 'moment';
import Pane from '@/components/decorative/Pane.vue';

export default {
  name: 'notifications',
  components: {
    Pane,
  },
  computed: {
    notificationKinds() {
      return {
        danger: this.$t('common.important'),
        system: this.$t('common.system'),
        personal: this.$t('common.personal'),
      };
    },
  },
  methods: {
    moment,
    formatter_text(text) {
      return text.split('\n').join('<br />');
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.notifications-page {
  &--empty {
    padding: 40px;
    color: $c-text-dimmed;
    text-align: center;
    text-transform: uppercase;
  }
}

.notification-pane {
  &__info {
    padding-bottom: 15px;
    color: $c-border-base;
  }

  &__content {
    line-height: 1.26;
  }

  &__kind {
    text-align: right;
    text-transform: uppercase;

    &--success {
      color: $c-success;
    }

    &--danger {
      color: $c-danger;
    }
  }

  & + & {
    margin-top: 20px;
  }
}
</style>
